<script setup lang="ts">
import { DatePicker } from 'v-calendar'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { useFacilityStore } from '@/stores/facility'

defineOptions({
  inheritAttrs: false,
})

const userData = useCookie<any>('userData')
const facilityStore = useFacilityStore()
const typeStore = useTypeStore()
const { data: facilityTypes } = await typeStore.fetchBy({ category: 'facility' })

const isAppSearchBarVisible = ref(false)
const router = useRouter()

const areaProps = {
  multiple: true,
}

const selectableSpaceEvent = ['店内', '屋外', '半屋外', '風除室', '空白']

const filter = ref({
  itemsPerPage: 20,
  page: 1,
  sortBy: [],
  orderBy: [],
  search: '',
  name: '',
  facility_types: [],
  address: [],
  is_ng: false,
  is_ng_item: false,
  is_new: false,
  is_campaign: false,
  space_event: [],
  weekday_price: [0, 0],
  weekend_price: [0, 0],
  holiday_price: [0, 0],
  event_date: {
    start: '',
    end: '',
  },
})

// 👉 Hotkey
const { ctrl_k, meta_k } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.ctrlKey && e.key === 'k' && e.type === 'keydown')
      e.preventDefault()
  },
})

// 👉 watching control + / to open dialog
watch([ctrl_k, meta_k], () => {
  isAppSearchBarVisible.value = true
})

// 👉 clear search result and close the dialog
const clearSearchAndCloseDialog = () => {
  filter.value = { ...facilityStore.reset }
  isAppSearchBarVisible.value = false
}

const getFocusOnSearch = () => {
  facilityStore.isFilter = true
  filter.value.search = facilityStore.searchGlobal
  facilityStore.filter = filter.value
  router.push({ name: userData.value.role === 'admin' ? 'admins-search' : 'clients-search' })

  clearSearchAndCloseDialog()
}

const areaStore = useAreaStore()

const areas = ref()

const getAreas = async () => {
  areas.value = await areaStore.fetchArea()
}

const handleChange = () => {
  if (!areas.value)
    getAreas()
}
</script>

<template>
  <div class="d-flex align-center ">
    <IconBtn
      class="me-1"
      @click="isAppSearchBarVisible = !isAppSearchBarVisible"
    >
      <VIcon
        size="26"
        icon="tabler-search"
      />
    </IconBtn>
  </div>

  <VTextField
    v-model="facilityStore.searchGlobal"
    class="d-none d-sm-block search"
    variant="plain"
    placeholder="施設名や住所をご入力ください"
    clearable
    clear-icon="tabler-circle-x"
    @change="filter.search = $event.target.value, getFocusOnSearch()"
    @click:clear="filter.search = '', getFocusOnSearch(), facilityStore.searchGlobal = ''"
  />
  <!-- 👉 App Bar Search -->
  <VDialog
    v-model="isAppSearchBarVisible"
    max-width="700"
    height="100%"
    :fullscreen="$vuetify.display.width < 600"
    class="app-bar-search-dialog"
    @keyup.esc="clearSearchAndCloseDialog"
  >
    <VCard>
      <VCardTitle>
        <div class="d-flex align-center justify-space-between text-center pa-2">
          <!-- 👉 Search Input -->
          <VTextField
            v-model="facilityStore.searchGlobal"
            autofocus
            placeholder="施設名や住所をご入力ください"
            clearable
            clear-icon="tabler-circle-x"
            density="comfortable"
            @change="filter.search = facilityStore.searchGlobal"
            @click:clear="filter.search = '', facilityStore.searchGlobal = ''"
          >
            <template #append>
              <IconBtn
                color="primary"
                class="d-sm-none"
                @click.stop="getFocusOnSearch"
              >
                <VIcon icon="tabler-search" />
              </IconBtn>

              <VBtn
                rounded="pill"
                @click.stop="getFocusOnSearch"
              >
                検索
              </VBtn>
            </template>
          </VTextField>

          <IconBtn @click.stop="clearSearchAndCloseDialog">
            <VIcon icon="tabler-x" />
          </IconBtn>
        </div>
      </VCardTitle>

      <!-- 👉 Perfect Scrollbar -->
      <PerfectScrollbar
        :options="{ wheelPropagation: false, suppressScrollX: true }"
        class="h-100"
      >
        <VCardText>
          <VRow>
            <VCol
              v-if="facilityTypes"
              cols="12"
              sm="6"
            >
              <h4>
                施設カテゴリ
              </h4>

              <div
                v-for="facility_type in facilityTypes"
                :key="`'type_'${facility_type.id}`"
              >
                <div class="d-flex align-center text-center">
                  <VCheckbox
                    v-model="filter.facility_types"
                    :value="facility_type.id"
                  />
                  <VAvatar
                    rounded
                    :icon="facility_type.image"
                  />
                  <label class="v-label v-label--clickable">
                    {{ facility_type.name }}
                  </label>
                </div>
              </div>

              <div class="mb-2">
                <h4>
                  エリア
                </h4>

                <ClientOnly>
                  <ElCascader
                    v-if="areas"
                    v-model="filter.address"
                    :teleported="false"
                    placeholder="エリア"
                    :props="areaProps"
                    :options="areas"
                    collapse-tags
                    collapse-tags-tooltip
                    value="value"
                    label="label"
                    filterable
                    clearable
                    @focus="handleChange"
                  />
                </ClientOnly>
              </div>
              <h4>
                価格
              </h4>

              <div>
                平日料金
              </div>

              <div class="d-flex justify-space-between align-center">
                <VTextField
                  v-model="filter.weekday_price[0]"
                  type="number"
                  step="1000"
                  min="0"
                />
                <span> ~ </span>

                <VTextField
                  v-model="filter.weekday_price[1]"
                  type="number"
                  step="1000"
                  min="0"
                  reverse
                />
              </div>
              <VRangeSlider
                min="0"
                max="100000"
                class="mt-2 mb-4"
                step="1000"
                @end="filter.weekday_price = $event"
              />

              <div>
                土曜料金
              </div>
              <div class="d-flex justify-space-between align-center">
                <VTextField
                  v-model="filter.weekend_price[0]"
                  type="number"
                  step="1000"
                  min="0"
                />
                <span> ~ </span>

                <VTextField
                  v-model="filter.weekend_price[1]"
                  type="number"
                  step="1000"
                  min="0"
                  reverse
                />
              </div>
              <VRangeSlider
                min="0"
                max="100000"
                class="mt-2 mb-4"
                step="1000"
                @end="filter.weekend_price = $event"
              />

              <div>
                日祝料金
              </div>
              <div class="d-flex justify-space-between align-center">
                <VTextField
                  v-model="filter.holiday_price[0]"
                  type="number"
                  step="1000"
                  min="0"
                />
                <span> ~ </span>

                <VTextField
                  v-model="filter.holiday_price[1]"
                  type="number"
                  step="1000"
                  min="0"
                  reverse
                />
              </div>
              <VRangeSlider
                min="0"
                max="100000"
                class="mt-2 mb-4"
                step="1000"
                @end="filter.holiday_price = $event"
              />
            </VCol>
            <VCol
              cols="12"
              sm="6"
            >
              <div class="mb-2">
                <h4>
                  開催ができない施設を含めて表示
                </h4>
                <VSwitch v-model="filter.is_ng" />
              </div>

              <div class="mb-2">
                <h4>
                  商材ができない施設を除く
                </h4>
                <VSwitch v-model="filter.is_ng_item" />
              </div>

              <div class="mb-2">
                <h4>
                  スペース区分での検索（店内・屋外・風除室）
                </h4>
                <div
                  v-for="space in selectableSpaceEvent"
                  :key="`'space_'${space}`"
                >
                  <div class="d-flex align-center text-center">
                    <VCheckbox
                      v-model="filter.space_event"
                      :label="space"
                      :value="space"
                    />
                  </div>
                </div>
              </div>

              <div class="mb-2">
                <h4>
                  注目施設
                </h4>
                <VSwitch v-model="filter.is_new" />
              </div>

              <div class="mb-2">
                <h4>
                  イチオシ施設のみを表示
                </h4>
                <VSwitch v-model="filter.is_campaign" />
              </div>

              <div class="mb-2">
                <div class="d-flex align-center justify-between">
                  <h4 class="mr-2">
                    開催日から検索
                  </h4>
                  <IconBtn @click.stop="filter.event_date = { start: '', end: '' }">
                    <VIcon icon="tabler-eraser" />
                  </IconBtn>
                </div>

                <div class="d-flex align-center justify-between">
                  <DatePicker
                    v-model.range.string="filter.event_date"
                    locale="ja"
                    :masks="{ modelValue: 'YYYY-MM-DD' }"
                  />
                </div>
              </div>
            </VCol>
          </VRow>
        </VCardText>
      </PerfectScrollbar>
    </VCard>
  </VDialog>
</template>

<style>
.search .v-field__input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
